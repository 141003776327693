import React from "react"
import { Col, Container, Row, Accordion, Card, Button } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import "./faq.less"
const FAQ = () => {
  const data = useStaticQuery(graphql`
    query faqQuery {
      strapiFaq {
        Description
        Header
        Questions {
          id
          Question
          Answer
        }
      }
    }
  `)
  return (
    <div className="faq-container">
      <Container>
        <h1 className="display-1">{data?.strapiFaq?.Header}</h1>
        <h6>{data?.strapiFaq?.Description}</h6>
        <Row>
          <Col sm={12}>
            <Accordion>
              {data?.strapiFaq?.Questions?.map((item, index) => {
                console.log(index);
                return (
                  <Card key={item.id}>
                    <Card.Header>
                      <Accordion.Toggle as={Card.Header} variant="link" eventKey={item.id}>
                        {item.Question}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={item.id}>
                      <Card.Body>{item.Answer}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FAQ
