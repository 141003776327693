import React from "react"
import Footer from "../components/footer/footer"
import NavigationBar,{NavBarBg} from "../components/navbar/navigationBar"
import BackgroundContainer from "../components/homepage/backgroundContainer/backgroundContainer"
import FAQ from "../components/faq/faq"
const FaqPage = () => {
  
  return (
    <div>
      <BackgroundContainer>
      <NavigationBar />
      <FAQ />

      </BackgroundContainer>
      <Footer />
    </div>
  )
}

export default FaqPage
